import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import ResponsivePagination from 'react-responsive-pagination';
import { jsonToTsv } from '../components/jsonToTabular';
import 'bootstrap/dist/css/bootstrap.css';

const defaultTableColumns = [
    {
        colHeadLabel: 'GENE',
        colId: 'gene',
        description: 'Gene symbol derived from VEP',
    },
    {
        colHeadLabel: 'CHROM',
        colId: 'chrom',
        description: 'Chromosome',
    },
    {
        colHeadLabel: 'POS',
        colId: 'pos',
        description: 'Position on the chromosome',
    },
    {
        colHeadLabel: 'REF',
        colId: 'ref',
        description: 'Reference allele',
    },
    {
        colHeadLabel: 'ALT',
        colId: 'alt',
        description: 'Alternate alleles',
    },
    {
        colHeadLabel: 'TYPE',
        colId: 'type',
        description: 'Variant type',
    },
    {
        colHeadLabel: 'AF',
        colId: 'af',
        description: 'Alternate allele frequency in the GREGoR dataset',
    },
    {
        colHeadLabel: 'AN',
        colId: 'an',
        description: 'Total number of alleles in called genotypes',
    },
    {
        colHeadLabel: 'AC_HET',
        colId: 'ac_het',
        description: 'Heterozygote allele count',
    },
    {
        colHeadLabel: 'AC_HOM',
        colId: 'ac_hom',
        description: 'Homozygote allele count',
    },
    {
        colHeadLabel: 'AC_HEMI',
        colId: 'ac_hemi',
        description: 'Hemizygous allele count',
    },
    {
        colHeadLabel: 'AC',
        colId: 'ac',
        description: 'Allele count',
    },
    {
        colHeadLabel: 'FILTER',
        colId: 'filter',
        description: 'Variant quality filters derived from VCF',
    },
    {
        colHeadLabel: 'CONSEQUENCE',
        colId: 'consequence',
        description: 'Consequence predictions derived from VEP',
    },
];

const selectedColumnOptions = [
    {
        name: 'gene',
        selected: true,
        changeable: false,
    },
    {
        name: 'chrom',
        selected: true,
        changeable: false,
    },
    {
        name: 'pos',
        selected: true,
        changeable: false,
    },
    {
        name: 'ref',
        selected: true,
        changeable: true,
    },
    {
        name: 'alt',
        selected: true,
        changeable: true,
    },
    {
        name: 'type',
        selected: true,
        changeable: true,
    },
    {
        name: 'af',
        selected: true,
        changeable: true,
    },
    {
        name: 'an',
        selected: true,
        changeable: true,
    },
    {
        name: 'ac_het',
        selected: true,
        changeable: true,
    },
    {
        name: 'ac_hom',
        selected: true,
        changeable: true,
    },
    {
        name: 'ac_hemi',
        selected: true,
        changeable: true,
    },
    {
        name: 'ac',
        selected: true,
        changeable: true,
    },
    {
        name: 'filter',
        selected: true,
        changeable: true,
    },
    {
        name: 'consequence',
        selected: true,
        changeable: true,
    },
];

const defaultFilterOptions = [
    {
        name: 'PASS',
        id: 'pass',
        selected: true,
    },
    {
        name: 'LowQual',
        id: 'lowqual',
        selected: true,
    },
    {
        name: 'NO_HQ_GENOTYPES',
        id: 'no_hq_genotypes',
        selected: true,
    },
];

const defaultTypeOptions = [
    {
        name: 'INDEL',
        id: 'indel',
        selected: true,
    },
    {
        name: 'SNV',
        id: 'snv',
        selected: true,
    },
];

function SearchDataTable({ data }) {
    const [tableData, setTableData] = useState(data);
    const [tableColumns, setTableColumns] = useState(defaultTableColumns);
    const [columnOptions, setColumnOptions] = useState(selectedColumnOptions);
    const [showDisplayOptions, setShowDisplayOptions] = useState(true);

    const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);
    const [typeOptions, setTypeOptions] = useState(defaultTypeOptions);

    // pagination configuration
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(50);
    const totalPages = Math.ceil(tableData.length / recordsPerPage);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = tableData.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        // update table data based on selected filters
        const updatedTableData = data.filter((row, col) => {
            // get all selected filterts
            const selectedFilters = filterOptions.filter((item) => item.selected);
            // get all selected types
            const selectedTypes = typeOptions.filter((item) => item.selected);
            // get all selected columns
            const selectedColumns = columnOptions.filter((item) => item.selected);
            // return rows that contain selected filters and types and columns
            return selectedFilters.find((filter) => row.filter.indexOf(filter.name) >= 0) && selectedTypes.find((type) => row.type.indexOf(type.name) >= 0) && selectedColumns.find((col) => row[col.name]);
        });
        setTableData(updatedTableData);
        // update table columns based on selected columns
        const updatedColumns = defaultTableColumns.filter((item) => columnOptions.find((col) => col.name === item.colId && col.selected));
        setTableColumns(updatedColumns);
    }, [data, filterOptions, typeOptions, columnOptions]);

    // handle page change in pagination
    function handlePageChange(page) {
        setCurrentPage(page);
    }

    // convert json to tsv and download file w/ hash in filename
    function handleExport(e, filename) {
        // generate a time stamp to append to the filename
        const date = new Date();
        filename = `${filename}_${date.getTime()}`;
        jsonToTsv(tableData, filename);
    }

    // handle popover column display changes
    function handleColumnsChange(e) {
        const newColumnsOptions = columnOptions.map((item) => {
            if (item.name === e.target.name) {
                return {
                    ...item,
                    selected: e.target.checked ? true : false,
                };
            }
            return item;
        });
        setColumnOptions(newColumnsOptions);
    }

    // handle filter options changes
    function handleFiltersChange(e) {
        // at least one filter must be selected
        const selected = filterOptions.filter((item) => item.selected);
        if (selected.length === 1 && selected[0].id === e.target.name) {
            return;
        }

        const newFilterOptions = filterOptions.map((item) => {
            if (item.id === e.target.name) {
                return {
                    ...item,
                    selected: e.target.checked ? true : false,
                };
            }
            return item;
        });
        setFilterOptions(newFilterOptions);
    }

    // handle variant type changes
    function handleTypesChange(e) {
        // at least one type must be selected
        const selected = typeOptions.filter((item) => item.selected);
        if (selected.length === 1 && selected[0].id === e.target.name) {
            return;
        }

        const newTypeOptions = typeOptions.map((item) => {
            if (item.id === e.target.name) {
                return {
                    ...item,
                    selected: e.target.checked ? true : false,
                };
            }
            return item;
        });
        setTypeOptions(newTypeOptions);
    }

    // displayed column options panel
    function ColumnDisplayPanel() {
        return (
            <div className="search-results-display-options-sidebar-panel column-options card mt-3">
                <div className="card-header">
                    Columns
                </div>
                <div className="card-body">
                    {columnOptions.map((item) => {
                        return (
                            <div key={`column-display-option-${item.name}`} className="column-display-option text-nowrap">
                                <input
                                    type="checkbox"
                                    id={item.name}
                                    name={item.name}
                                    checked={item.selected}
                                    onChange={(e) => handleColumnsChange(e)}
                                    disabled={!item.changeable ? true : false}
                                />
                                <label htmlFor={`column-display-option-${item.name}`}>{item.name.toUpperCase()}</label>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    // filter options panel
    function FilterOptionsPanel() {
        return (
            <div className="search-results-display-options-sidebar-panel filter-options card mt-3">
                <div className="card-header">
                    Filters
                </div>
                <div className="card-body">
                    {filterOptions.map((item) => {
                        return (
                            <div key={`filter-select-option-${item.name}`} className="filter-select-option text-nowrap">
                                <input
                                    type="checkbox"
                                    id={item.id}
                                    name={item.id}
                                    checked={item.selected}
                                    onChange={(e) => handleFiltersChange(e)}
                                />
                                <label htmlFor={`filter-select-option-${item.name}`}>{item.name}</label>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    // variant types panel
    function VariantTypesPanel() {
        return (
            <div className="search-results-display-options-sidebar-panel type-options card mt-3">
                <div className="card-header">
                    Types
                </div>
                <div className="card-body">
                    {typeOptions.map((item) => {
                        return (
                            <div key={`type-select-option-${item.name}`} className="type-select-option text-nowrap">
                                <input
                                    type="checkbox"
                                    id={item.id}
                                    name={item.id}
                                    checked={item.selected}
                                    onChange={(e) => handleTypesChange(e)}
                                />
                                <label htmlFor={`type-select-option-${item.name}`}>{item.name}</label>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return (
        <div className="search-results-container">
            <div className="row">
                <div className={`search-results-display-options-sidebar col-12 col-lg-2 col-md-12 ${showDisplayOptions ? 'show' : 'hidden'}`}>
                    <h6 className="search-results-display-options-sidebar-title fw-bold mt-2">Display options</h6>
                    <FilterOptionsPanel />
                    <VariantTypesPanel />
                    <ColumnDisplayPanel />
                </div>
                <div className={`table-responsive search-data-table col-12 col-md-12 ${showDisplayOptions ? 'col-lg-10' : 'col-lg-12'}`}>
                    <div className="pagination-container d-flex align-items-center justify-content-between">
                        <div className="table-controls d-flex align-items-center mb-3">
                            <div className="display-options-sidebar-control">
                                <button
                                    type="button"
                                    className="btn btn-secondary result-display-options-control"
                                    onClick={(e) => setShowDisplayOptions(!showDisplayOptions)}
                                >
                                    <i className="bi bi-sliders"></i>
                                </button>
                            </div>
                            <div className="records-per-page-select d-flex align-items-center">
                                <span className="records-select-label">Display records per page:</span>
                                <select
                                    className="form-select records-select"
                                    value={recordsPerPage}
                                    onChange={(e) => setRecordsPerPage(e.target.value)}
                                >
                                    <option>50</option>
                                    <option>100</option>
                                    <option>200</option>
                                    <option>400</option>
                                </select>
                            </div>
                        </div>
                        <div className="export-data-container mb-3">
                            <button
                                type="button"
                                className="btn btn-dark"
                                onClick={(e) => handleExport(e, 'GREGoR_variant_data')}
                            >
                                <i className="bi bi-download"></i>&nbsp;&nbsp;Export Data
                            </button>
                        </div>
                    </div>
                    <table className="table table-bordered table-hover mb-4">
                        <thead className="table-dark">
                            <tr>
                                {tableColumns.map((item) => {
                                    return (
                                        <th key={`col-head-${item.colHeadLabel}`} scope="col" className={`col-head-${item.colHeadLabel} text-center`}>
                                            {item.colHeadLabel}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {currentRecords.map((row) => (
                                <tr key={`${row.chrom}-${row.pos}-${row.ref}-${row.alt}-${row.gene}`}>
                                    {tableColumns.map((item) => {
                                        return (
                                            <td key={`col-${item.colHeadLabel}-${row[item.colId]}`} className="text-break" style={{width: `${100 / tableColumns.length}%`}}>
                                                {row[item.colId] !== null || row[item.colId] !== '' ? row[item.colId] : '-'}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-container d-flex align-items-center justify-content-end">
                        <ResponsivePagination
                            total={totalPages}
                            current={currentPage}
                            maxWidth={800}
                            extraClassName="justify-content-end"
                            onPageChange={page => handlePageChange(page)}
                        />
                    </div>
                    {tableColumns.map((item) => {
                        return (
                            <Tooltip
                                key={`tooltip-${item.colHeadLabel}`}
                                anchorSelect={`.col-head-${item.colHeadLabel}`}
                                place="bottom"
                                style={{maxWidth: `${90 / tableColumns.length}%`}}
                            >
                                {item.description}
                            </Tooltip>
                        );
                    })}
                    <Tooltip className="tooltip-result-display-options-control" anchorSelect=".result-display-options-control" place="top">
                        {showDisplayOptions ? 'Hide' : 'Show'} display options
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

SearchDataTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            CHROM: PropTypes.string,
            POS: PropTypes.number,
            REF: PropTypes.string,
            ALT: PropTypes.string,
            AC: PropTypes.number,
            AF: PropTypes.number,
            AN: PropTypes.number,
            AC_HET: PropTypes.number,
            AC_HOM: PropTypes.number,
            AC_HRMI: PropTypes.number,
            TYPE: PropTypes.string,
            GENE: PropTypes.string,
            FILTER: PropTypes.string,
            CONSEQUENCE: PropTypes.string,
        })
    ),
};

SearchDataTable.defaultProps = {
    data: [],
};

export default SearchDataTable;
