import React from 'react';

// This is a functional component that displays information about the app
function About() {
    return (
        <div className="about-section bg-body-tertiary p-5 rounded">
            <h1>About the GREGoR Variant Browser</h1>
            <p className="description">
                This variant browser summarizes genetic variation (single nucleotide variants and short
                insertions and deletions) observed in whole genome sequencing data from 1,420 individuals
                in the Genomics Research to Elucidate the Genetics of Rare diseases (GREGoR) Consortium.
                This data was contributed by the GREGoR member Research Centers, and subsequently
                harmonized by the GREGoR Data Coordinating Center as documented in the supporting
                {' '}
                <a
                    href="https://gregorconsortium.org/sites/default/files/data/WGS%20reprocessing%20methods%20summary.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="gregor_orange"
                >
                    methods documentation
                </a>.
            </p>
            <p className="description">
                The goal of this variant browser is to provide variant site information as an intermediate
                step, prior to submitting a Data Access Request to controlled access GREGoR Data stored on
                the AnVIL platform. This publicly available resource includes genomic coordinate, reference
                and alternate alleles, allele counts and allele frequency information as well as additional
                quality and functional annotations.
            </p>
        </div>
    )
}

export default About;
