import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import VariantBrowser  from "./VariantBrowser/variantBrowser";
import GregorLogo from "./assets/GREGoR_Logo_Horizontal.png";
import Footer from "./components/Footer";

function App() {
  return (
      <div>
        {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<VariantBrowser />} />
          </Route>
        </Routes>
      </div>
  );
}

export default App;

function Layout() {
  return (
      <div className="body-content d-flex flex-column min-vh-100">
        {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
        <nav className="navbar navbar-expand-lg border-bottom mb-3" id="main-nav">
          <div className="container-fluid">
            <div className="navbar-brand mb-0 d-flex align-items-center">
              <a
                href="https://gregorconsortium.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={GregorLogo} alt="GREGoR Consortium Logo" className="gregor-consortium-logo image-fluid" />
              </a>
              <Link to="/" className="nav-app-name mx-1">
                Variant Browser
              </Link>
            </div>
          </div>
        </nav>

        {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
        <Outlet />
        <div className="footer">
          <div className="container-fluid px-4">
            <div className="footer-bottom">
              <Footer />
            </div>
          </div>
        </div>
      </div>
  );
}
