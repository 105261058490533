import React from 'react';
import PropTypes from 'prop-types';

const chromList = [
    'chr1', 'chr2', 'chr3', 'chr4', 'chr5', 'chr6', 'chr7', 'chr8', 'chr9', 'chr10',
    'chr11', 'chr12', 'chr13', 'chr14', 'chr15', 'chr16', 'chr17', 'chr18', 'chr19',
    'chr20', 'chr21', 'chr22', 'chrX', 'chrY', 'chrM'
];

function ChromosomeList({ handleSelect, chromosome }) {
    return (
        <select
            className="form-select chromosome-select mt-2"
            value={chromosome}
            onChange={(event) => handleSelect(event)}
        >
            <option></option>
            {chromList.map((chrom) => (
                <option key={chrom} value={chrom} disabled={chrom.match(/chrX|chrY|chrM/)}>
                    {chrom}
                </option>
            ))}
        </select>
    );
}

ChromosomeList.propTypes = {
    handleSelect: PropTypes.func.isRequired,
    chromosome: PropTypes.string.isRequired
};

export default ChromosomeList;
